import { percentage } from "../lib/scoring_logic";
import BoardRecord from "../model/BoardRecord/BoardRecord";
import { Direction } from "../model/Deal/Direction";
import Game from "../model/Game/Game";

type Props = {
  games: Game[];
  names: string[];
};

export const Honors = ({ games, names }: Props) => {
  const calculate_honors = () => {
    let honorsToReturn: number[] = [];
    honorsToReturn[Direction.North] = 0;
    honorsToReturn[Direction.South] = 0;
    honorsToReturn[Direction.East] = 0;
    honorsToReturn[Direction.West] = 0;
    games.forEach((game) => {
      game.board_records.forEach((board) => {
        board.deal.hands.forEach((hand) => {
          if (hand.direction === Direction.North) {
            honorsToReturn[Direction.North] += hand.playerHand.getHonors(
              board.contract.suit!
            );
          } else if (hand.direction === Direction.South) {
            honorsToReturn[Direction.South] += hand.playerHand.getHonors(
              board.contract.suit!
            );
          } else if (hand.direction === Direction.East) {
            honorsToReturn[Direction.East] += hand.playerHand.getHonors(
              board.contract.suit!
            );
          } else if (hand.direction === Direction.West) {
            honorsToReturn[Direction.West] += hand.playerHand.getHonors(
              board.contract.suit!
            );
          }
        });
      });
    });

    return [honorsToReturn];
  };

  const [honors] = calculate_honors();
  return (
    <div className="grid grid-cols-2" style={{ textAlign: "center" }}>
      <div
        className="col-span-2 bg-slate-50 font-bold border-l-2 border-r-2"
        style={{ textAlign: "center" }}
      >
        Honor Points
      </div>
      <div className="bg-slate-200">{names[Direction.North]}</div>
      <div className="bg-slate-200">{honors[Direction.North]}</div>
      <div className="bg-slate-50">{names[Direction.South]}</div>
      <div className="bg-slate-50 ">{honors[Direction.South]}</div>
      <div className="bg-slate-200">{names[Direction.East]}</div>
      <div className="bg-slate-200">{honors[Direction.East]}</div>
      <div className="bg-slate-50">{names[Direction.West]}</div>
      <div className="bg-slate-50">{honors[Direction.West]}</div>
    </div>
  );
};
