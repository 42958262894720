export default class Rubber {
  north_points = 0;
  south_points = 0;
  east_points = 0;
  west_points = 0;
  ns_vulnerable = false;
  ew_vulernable = false;
  ns_leg = 0;
  ew_leg = 0;

  ns_points = 0;
  ew_points = 0;
  games_played = 0;

  constructor() {}
}
