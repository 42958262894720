export enum Rank {
  TWO = "2",
  THREE = "3",
  FOUR = "4",
  FIVE = "5",
  SIX = "6",
  SEVEN = "7",
  EIGHT = "8",
  NINE = "9",
  TEN = "T",
  JACK = "J",
  QUEEN = "Q",
  KING = "K",
  ACE = "A",
}

export namespace Rank {
  export const predicate = (a: any, b: any) => {
    let map: any = {};
    map[Rank.TWO] = 2;
    map[Rank.THREE] = 3;
    map[Rank.FOUR] = 4;
    map[Rank.FIVE] = 5;
    map[Rank.SIX] = 6;
    map[Rank.SEVEN] = 7;
    map[Rank.EIGHT] = 8;
    map[Rank.NINE] = 9;
    map[Rank.TEN] = 10;
    map[Rank.JACK] = 11;
    map[Rank.QUEEN] = 12;
    map[Rank.KING] = 13;
    map[Rank.ACE] = 14;
    if (map[a] > map[b]) {
      return -1;
    }

    if (map[a] < map[b]) {
      return 1;
    }

    return 0;
  };
}
