import { Direction } from "../model/Deal/Direction";

type Props = {
  names: string[];
  ns_points: number;
  ew_points: number;
};

export const Score = ({ names, ns_points, ew_points }: Props) => {
  return (
    <div className="border-l-2 border-r-2" style={{ textAlign: "center" }}>
      <div
        className="col-span-2 bg-slate-50 font-bold "
        style={{ textAlign: "center", height: "30px" }}
      >
        Score
      </div>
      <div className="bg-slate-200">
        <span>{names[Direction.North] + " / " + names[Direction.South]}</span>
      </div>
      <div className="bg-slate-200 font-bold" style={{ height: "30px" }}>
        <span>{ns_points}</span>
      </div>
      <div className="bg-slate-50">
        <span>{names[Direction.East] + " / " + names[Direction.West]}</span>
      </div>
      <div className="bg-slate-50 font-bold" style={{ height: "30px" }}>
        <span>{ew_points}</span>
      </div>
    </div>
  );
};
