import { getNames, percentage } from "../lib/scoring_logic";
import { Direction } from "../model/Deal/Direction";
import Rubber from "../model/Rubber/Rubber";
import { GridItem } from "./GridItem";
import { Points } from "./Points";
import { Score } from "./Score";

type Props = {
  rubber: Rubber;
  name: string;
};

export const RubberComponent = ({ rubber, name }: Props) => {
  const names = getNames();

  const getRubberPoints = (): number[] => {
    let pointToReturn = [];
    pointToReturn[Direction.North] = rubber.north_points;
    pointToReturn[Direction.South] = rubber.south_points;
    pointToReturn[Direction.East] = rubber.east_points;
    pointToReturn[Direction.West] = rubber.west_points;
    return pointToReturn;
  };

  const points: number[] = getRubberPoints();

  return (
    <div>
      <div className="flex items-center justify-center mb-2 border-4">
        <div className="grid grid-cols-2 w-full">
          <div
            className="col-span-2 bg-slate-300 text-lg align-top align-text-top"
            style={{ textAlign: "center", height: "35px" }}
          >
            <span
              className="align-top align-text-top"
              style={{ verticalAlign: "top" }}
            >
              {name + " (" + rubber.games_played + " games)"}
            </span>
          </div>
          <Score
            names={names}
            ns_points={rubber.ns_points}
            ew_points={rubber.ew_points}
          />
          <Points names={names} points={points} />
        </div>
      </div>
    </div>
  );
};
