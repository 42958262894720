import { getNames, percentage } from "../lib/scoring_logic";
import BoardRecord from "../model/BoardRecord/BoardRecord";
import { Direction } from "../model/Deal/Direction";
import Game from "../model/Game/Game";
import Rubber from "../model/Rubber/Rubber";
import { Games } from "./DragDrop";
import { GridItem } from "./GridItem";
import { HandsMade } from "./HandsMade";
import { Honors } from "./Honors";
import { OpeningHands } from "./OpeningHands";
import { Points } from "./Points";
import { Score } from "./Score";
import { SlamsMade } from "./SlamsMade";

type Props = {
  game: Game;
  handsCheck: boolean;
  slamCheck: boolean;
  openCheck: boolean;
  honorsCheck: boolean;
};

export const Summary = ({
  game,
  handsCheck,
  slamCheck,
  openCheck,
  honorsCheck,
}: Props) => {
  const names = getNames();

  const getPlayerPoints = (): number[] => {
    let pointToReturn: number[] = [];
    pointToReturn[Direction.North] = 0;
    pointToReturn[Direction.South] = 0;
    pointToReturn[Direction.East] = 0;
    pointToReturn[Direction.West] = 0;

    game.board_records.forEach((deal) => {
      pointToReturn[Direction.North] += deal.deal.hands
        .filter((x) => x.direction === Direction.North)[0]
        .playerHand.getPoints();

      pointToReturn[Direction.South] += deal.deal.hands
        .filter((x) => x.direction === Direction.South)[0]
        .playerHand.getPoints();

      pointToReturn[Direction.East] += deal.deal.hands
        .filter((x) => x.direction === Direction.East)[0]
        .playerHand.getPoints();

      pointToReturn[Direction.West] += deal.deal.hands
        .filter((x) => x.direction === Direction.West)[0]
        .playerHand.getPoints();
    });
    return pointToReturn;
  };

  const getOpeningHands = (): number[] => {
    let openhands: number[] = [];
    openhands[Direction.North] = 0;
    openhands[Direction.South] = 0;
    openhands[Direction.East] = 0;
    openhands[Direction.West] = 0;

    game.board_records.forEach((deal) => {
      if (
        deal.deal.hands
          .filter((x) => x.direction === Direction.North)[0]
          .playerHand.getPoints() > 12
      ) {
        openhands[Direction.North] += 1;
      }

      if (
        deal.deal.hands
          .filter((x) => x.direction === Direction.South)[0]
          .playerHand.getPoints() > 12
      ) {
        openhands[Direction.South] += 1;
      }

      if (
        deal.deal.hands
          .filter((x) => x.direction === Direction.East)[0]
          .playerHand.getPoints() > 12
      ) {
        openhands[Direction.East] += 1;
      }

      if (
        deal.deal.hands
          .filter((x) => x.direction === Direction.West)[0]
          .playerHand.getPoints() > 12
      ) {
        openhands[Direction.West] += 1;
      }
    });
    return openhands;
  };

  const points: number[] = getPlayerPoints();
  const opening: number[] = getOpeningHands();
  let ns_rubber_points = 0;
  let ew_rubber_Points = 0;
  let numCols =
    2 +
    Number(slamCheck) +
    Number(openCheck) +
    Number(handsCheck) +
    Number(honorsCheck);
  const cols = "grid-cols-" + numCols;
  const colspan = "col-span-" + numCols;
  const getRubberPoints = () => {
    game.rubbers.forEach((rubber) => {
      ns_rubber_points += rubber.ns_points;
      ew_rubber_Points += rubber.ew_points;
    });
  };
  getRubberPoints();

  return (
    <div>
      <div className="flex items-center justify-center mb-5 border-4">
        <div className={"grid  w-full " + cols}>
          <div
            className={"bg-slate-300 text-lg " + colspan}
            style={{ textAlign: "center", height: "35px" }}
          >
            {game.date.toDateString() +
              " (" +
              game.board_records.length +
              " hands)"}
          </div>
          <Score
            names={names}
            ns_points={ns_rubber_points}
            ew_points={ew_rubber_Points}
          />
          <Points names={names} points={points} />
          {handsCheck && <HandsMade games={[game]} names={names} />}
          {slamCheck && <SlamsMade games={[game]} names={names} />}
          {openCheck && <OpeningHands openingHands={opening} names={names} />}
          {honorsCheck && <Honors games={[game]} names={names} />}
        </div>
      </div>
    </div>
  );
};
