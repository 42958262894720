export enum BiddingSuit {
  CLUBS = 0,
  DIAMONDS = 1,
  HEARTS = 2,
  SPADES = 3,
  NO_TRUMP = 4,
}
export namespace BiddingSuit {
  export function fromString(string: string): BiddingSuit | null {
    if (string === "C") {
      return BiddingSuit.CLUBS;
    } else if (string === "D") {
      return BiddingSuit.DIAMONDS;
    } else if (string === "H") {
      return BiddingSuit.HEARTS;
    } else if (string === "S") {
      return BiddingSuit.SPADES;
    } else if (string === "NT") {
      return BiddingSuit.NO_TRUMP;
    } else {
      console.log("failed", string);
      return null;
    }
  }
  export function firstTrickValue(bidding_suit: BiddingSuit): number {
    if (bidding_suit === BiddingSuit.CLUBS) return 20;
    if (bidding_suit === BiddingSuit.DIAMONDS) return 20;
    if (bidding_suit === BiddingSuit.HEARTS) return 30;
    if (bidding_suit === BiddingSuit.SPADES) return 30;
    if (bidding_suit === BiddingSuit.NO_TRUMP) return 40;
    else return 0;
  }
  export function trickValue(bidding_suit: BiddingSuit): number {
    if (bidding_suit === BiddingSuit.CLUBS) return 20;
    if (bidding_suit === BiddingSuit.DIAMONDS) return 20;
    if (bidding_suit === BiddingSuit.HEARTS) return 30;
    if (bidding_suit === BiddingSuit.SPADES) return 30;
    if (bidding_suit === BiddingSuit.NO_TRUMP) return 30;
    else return 0;
  }
  export function firstUndertrickValue(
    vulnerable: boolean,
    doubled: number
  ): number {
    if (!vulnerable && doubled === 0) return 50;
    if (!vulnerable && doubled === 1) return 100;
    if (!vulnerable && doubled === 2) return 200;
    if (vulnerable && doubled === 0) return 100;
    if (vulnerable && doubled === 1) return 200;
    if (vulnerable && doubled === 2) return 400;
    else return 0;
  }

  export function secondUndertrickValue(
    vulnerable: boolean,
    doubled: number
  ): number {
    if (!vulnerable && doubled === 0) return 50;
    if (!vulnerable && doubled === 1) return 200;
    if (!vulnerable && doubled === 2) return 400;
    if (vulnerable && doubled === 0) return 100;
    if (vulnerable && doubled === 1) return 300;
    if (vulnerable && doubled === 2) return 600;
    else return 0;
  }
  export function subsequentUndertrickValue(
    vulnerable: boolean,
    doubled: number
  ): number {
    if (!vulnerable && doubled === 0) return 50;
    if (!vulnerable && doubled === 1) return 300;
    if (!vulnerable && doubled === 2) return 600;
    if (vulnerable && doubled === 0) return 100;
    if (vulnerable && doubled === 1) return 300;
    if (vulnerable && doubled === 2) return 600;
    else return 0;
  }
}
