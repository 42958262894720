export enum Direction {
  North = 3,
  South = 1,
  East = 4,
  West = 2,
}

export namespace Direction {
  export function previous(direction: Direction) {
    if (direction === Direction.North) return Direction.West;
    if (direction === Direction.South) return Direction.East;
    if (direction === Direction.West) return Direction.South;
    if (direction === Direction.East) return Direction.North;
    console.log("failed direction");
  }
  export function next(direction: Direction) {
    if (direction === Direction.North) return Direction.East;
    if (direction === Direction.South) return Direction.West;
    if (direction === Direction.West) return Direction.North;
    if (direction === Direction.East) return Direction.South;
    console.log("failed direction");
  }
  export function partner(direction: Direction) {
    if (direction === Direction.North) return Direction.South;
    if (direction === Direction.South) return Direction.North;
    if (direction === Direction.West) return Direction.East;
    if (direction === Direction.East) return Direction.West;
    console.log("failed direction");
  }
}
