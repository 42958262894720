import { Direction } from "./Direction";
import Hand from "./Hand";

export default class Deal {
  dealer;
  hands;

  constructor(dealer: Direction, hands: Hand[]) {
    this.dealer = dealer;
    this.hands = hands;
  }
}
