import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { DragDrop } from "./components/DragDrop";
import { RubberComponent } from "./components/RubberComponent";
import { rubbers } from "./lib/scoring_logic";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div id="App" className="App">
      <DragDrop />
      <ToastContainer
        position="bottom-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
