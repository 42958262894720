import { percentage } from "../lib/scoring_logic";
import { Direction } from "../model/Deal/Direction";

type Props = {
  names: string[];
  points: number[];
};

export const Points = ({ names, points }: Props) => {
  const totalPoints =
    points[Direction.North] +
    points[Direction.South] +
    points[Direction.East] +
    points[Direction.West];
  return (
    <div
      className="grid grid-cols-2 border-l-2 border-r-2"
      style={{ textAlign: "center" }}
    >
      <div
        className="col-span-2 bg-slate-50 font-bold "
        style={{ textAlign: "center", height: "30px" }}
      >
        Points
      </div>
      <div className="bg-slate-200">
        <div className="pdf_col">{names[Direction.North]}</div>
      </div>
      <div className="bg-slate-200">
        <div className="pdf_col">
          {points[Direction.North].toString() +
            " (" +
            percentage(points[Direction.North], totalPoints) +
            " %)"}
        </div>
      </div>
      <div className="bg-slate-50">
        <div className="pdf_col">{names[Direction.South]}</div>
      </div>
      <div className="bg-slate-50">
        <div className="pdf_col">
          {points[Direction.South].toString() +
            " (" +
            percentage(points[Direction.South], totalPoints) +
            " %)"}
        </div>
      </div>
      <div className="bg-slate-200">
        <div className="pdf_col">{names[Direction.East]}</div>
      </div>
      <div className="bg-slate-200">
        <div className="pdf_col">
          {points[Direction.East].toString() +
            " (" +
            percentage(points[Direction.East], totalPoints) +
            " %)"}
        </div>
      </div>
      <div className="bg-slate-50">
        <div className="pdf_col">{names[Direction.West]}</div>
      </div>
      <div className="bg-slate-50">
        <div className="pdf_col">
          {points[Direction.West].toString() +
            " (" +
            percentage(points[Direction.West], totalPoints) +
            " %)"}
        </div>
      </div>
      <div className="col-span-2 border-t-2 border-indigo-500"></div>
      <div className="bg-slate-200">
        <div className="pdf_col">
          {names[Direction.North] + " / " + names[Direction.South]}
        </div>
      </div>
      <div className="bg-slate-200">
        <div className="pdf_col">
          {points[Direction.North] +
            points[Direction.South] +
            " (" +
            percentage(
              points[Direction.North] + points[Direction.South],
              totalPoints
            ) +
            " %)"}
        </div>
      </div>
      <div className="bg-slate-50">
        <div className="pdf_col">
          {names[Direction.East] + " / " + names[Direction.West]}
        </div>
      </div>
      <div className="bg-slate-50">
        <div className="pdf_col">
          {points[Direction.East] +
            points[Direction.West] +
            " (" +
            percentage(
              points[Direction.East] + points[Direction.West],
              totalPoints
            ) +
            " %)"}
        </div>
      </div>
    </div>
  );
};
