import { Direction } from "../Deal/Direction";

export default class Name {
  direction;
  name;

  constructor(direction: Direction, name: string) {
    this.direction = direction;
    this.name = name;
  }
}
