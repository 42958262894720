import { BiddingSuit } from "../BoardRecord/BiddingSuit";

export enum Suit {
  Clubs = 4,
  Diamonds = 3,
  Hearts = 2,
  Spades = 1,
}

export namespace Suit {
  export function fromString(string: string) {
    if (string === "C") return Suit.Clubs;
    if (string === "D") return Suit.Diamonds;
    if (string === "H") return Suit.Hearts;
    if (string === "S") return Suit.Spades;
  }
  export function fromBiddingSuit(biddingSuit: BiddingSuit) {
    if (biddingSuit === BiddingSuit.CLUBS) return Suit.Clubs;
    if (biddingSuit === BiddingSuit.DIAMONDS) return Suit.Diamonds;
    if (biddingSuit === BiddingSuit.HEARTS) return Suit.Hearts;
    if (biddingSuit === BiddingSuit.SPADES) return Suit.Spades;
  }
}
