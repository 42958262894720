import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { text } from "stream/consumers";
import { Direction } from "../model/Deal/Direction";
import Card, { CardFromString } from "../model/Deal/Card";
import { Suit } from "../model/Deal/Suit";
import { Rank } from "../model/Deal/Rank";
import PlayerHand from "../model/Deal/PlayerHand";
import Deal from "../model/Deal/Deal";
import Hand from "../model/Deal/Hand";
import Name from "../model/BoardRecord/Name";
import Contract from "../model/BoardRecord/Contract";
import { BiddingSuit } from "../model/BoardRecord/BiddingSuit";
import BoardRecord from "../model/BoardRecord/BoardRecord";
import { getRubberScoring, rubbers } from "../lib/scoring_logic";
import { RubberComponent } from "./RubberComponent";
import Rubber from "../model/Rubber/Rubber";
import { Summary } from "./Summary";
import JsPDF from "jspdf";
import cards from "../images/cards2.png";
import supabase from "../config/supabaseClient";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Datepicker from "react-tailwindcss-datepicker";
import { number } from "yargs";
import Game from "../model/Game/Game";
import { GameSummary } from "./GameSummary";

const fileTypes = ["lin"];
var deckset: Card[] = [];

const Suits: {
  value: number;
}[] = Object.values(Suit)
  .filter((value) => typeof value == "number")
  .map((value) => ({ value: value as number }));

const Ranks: {
  value: string;
}[] = Object.values(Rank)
  .filter((value) => typeof value == "string")
  .map((value) => ({ value: value as string }));

// export const Deals: Deal[] = [];
export const BoardRecords: BoardRecord[] = [];
export const Games: Game[] = [];

const createCss = () => {
  if (document.getElementById("gCSS")) {
    return;
  }
  var style: any = document.createElement("style");
  style.type = "text/css";
  style.id = "gCSS";
  style.innerHTML = "";
  document.getElementsByTagName("head")[0].appendChild(style);
};

export const DragDrop = ({}) => {
  // console.log(supabase);
  createCss();
  const [file, setFile] = useState<File>();
  const [rubberComponents, setRubbers] = useState<Rubber[]>([]);
  const [handsCheck, setHandsCheck] = useState<boolean>(true);
  const [slamCheck, setSlamCheck] = useState<boolean>(true);
  const [openCheck, setOpenCheck] = useState<boolean>(true);
  const [honorsCheck, setHonorsCheck] = useState<boolean>(false);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [games, setGames] = useState<Game[]>([]);
  const [lin, setLin] = useState<string | null>(null);
  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });
  const [userName, setUserName] = useState<string | null>(null);

  const handleValueChange = (newValue: any) => {
    setValue(newValue);
    loadFromDatabase(newValue);
  };

  const handleUserNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value);
  };

  const handleChange = async (file: File) => {
    setFile(file);
    var contents = await file.text();

    parseFile(contents, new Date());
    loadIntoDatabase(contents);
  };

  const parseFile = (contents: string, date: Date) => {
    setLin(contents);
    BoardRecords.length = 0;
    rubbers.length = 0;
    let board_records = ParseMultiLin(contents);
    let game_rubbers: Rubber[] = [];

    board_records.forEach((board) => {
      BoardRecords.push(board);
    });
    getRubberScoring();
    setRubbers(rubbers);
    rubbers.forEach((rubber) => {
      game_rubbers.push(rubber);
    });
    games.push(new Game(board_records, game_rubbers, date));
    var styleobj: any = document.getElementsByClassName("testtext");
    console.log(games);
  };

  const loadFromDatabase = async (newValue: any) => {
    let contents = "";
    if (newValue.startDate && newValue.endDate) {
      const { data, error } = await supabase
        .from("games")
        .select()
        .lt("created_at", newValue.endDate)
        .gt("created_at", newValue.startDate)
        .or(
          "south.eq." +
            userName +
            ",north.eq." +
            userName +
            ",east.eq." +
            userName +
            ",west.eq." +
            userName
        );

      if (data && data.length > 0) {
        console.log(data);
        data.forEach((game) => {
          parseFile(game.lin_file, new Date(game.created_at));
        });
      } else {
        toast("No games match that date range and username");
      }

      if (error) {
        toast.error("Issue loading date range");
        console.log(error);
      }
    }
  };

  const loadIntoDatabase = async (lin_file: string) => {
    const lin_sub = lin_file.substring(56, 69);

    const { data: fetch, error } = await supabase
      .from("games")
      .select("*")
      .eq("lin_sub", lin_sub);

    if (error) {
      toast.error("Issue fetching data");
      console.log(error);
    }

    if (fetch && fetch.length > 0) {
      const { data, error } = await supabase
        .from("games")
        .update([
          {
            north: BoardRecords[0].names[Direction.North - 1].name,
            south: BoardRecords[0].names[Direction.South - 1].name,
            east: BoardRecords[0].names[Direction.East - 1].name,
            west: BoardRecords[0].names[Direction.West - 1].name,
            lin_file: lin_file,
            lin_sub: lin_sub,
          },
        ])
        .eq("lin_sub", lin_sub)
        .select();
      if (error) {
        toast.error("Issue updating data");
        console.log(error);
      }
      if (data) {
        toast.success("Database Entry Updated");
      }
    } else if (fetch) {
      const { data, error } = await supabase
        .from("games")
        .insert([
          {
            created_at: new Date(),
            north: BoardRecords[0].names[Direction.North - 1].name,
            south: BoardRecords[0].names[Direction.South - 1].name,
            east: BoardRecords[0].names[Direction.East - 1].name,
            west: BoardRecords[0].names[Direction.West - 1].name,
            lin_file: lin_file,
            lin_sub: lin_sub,
          },
        ])
        .select();
      if (error) {
        toast.error("Issue adding data");
        console.log(error);
      }
      if (data) {
        toast.success("Entry added to database");
      }
    }
  };

  const ParseMultiLin = (contents: string) => {
    deckset = [];
    const returnRecords: BoardRecord[] = [];
    for (let i = 0; i < Suits.length; i++) {
      for (let j = 0; j < Ranks.length; j++) {
        deckset.push(new Card(Suits[i].value, Ranks[j].value as Rank));
      }
    }
    // var c = contents.slice(0, 8);
    // if (c !== "qx||pg||") {
    //   contents = contents.replace("qx", "\nqx");
    //   var re = /(?<!\n)^/;
    //   contents = contents.replace(re, "qx||pg||");
    //   //   console.log(contents);
    // }

    var board_strings = splitLines(contents);
    board_strings.forEach((board_string) => {
      if (board_string.length < 1) return;
      let deal = parse_deal(board_string);
      // Deals.push(deal);
      var board_record = parse_board_record(board_string, deal);
      returnRecords.push(board_record);
    });
    return returnRecords;
  };

  const parse_board_record = (board_string: string, deal: Deal) => {
    var playerNames: Name[] = [];
    var names_string = board_string
      .substring(board_string.indexOf("|pn|") + 4)
      .split("|")[0];
    var i = 1;
    names_string.split(",").forEach((name) => {
      playerNames.push(new Name(i, name));
      i++;
    });
    var raw_bidding;
    if (board_string.indexOf("|pc|") > 0) {
      raw_bidding = board_string
        .substring(
          board_string.indexOf("|mb|") + 4,
          board_string.indexOf("|pc|")
        )
        .split("|mb|");
    } else {
      raw_bidding = board_string
        .substring(
          board_string.indexOf("|mb|") + 4,
          board_string.indexOf("|zz|")
        )
        .split("|mb|");
    }
    var bidding_record = bidding_record_from_raw(raw_bidding);
    var contract_string = bidding_record[bidding_record.length - 4];
    if (contract_string === "X" || contract_string === "XX") {
      for (let i = bidding_record.length - 1; i >= 0; i--) {
        if (
          bidding_record[i] !== "X" &&
          bidding_record[i] !== "XX" &&
          bidding_record[i] !== "PASS"
        ) {
          contract_string = bidding_record[i] + contract_string;
          break;
        }
      }
    }
    let contract = create_contract_from_string(contract_string);
    let play_record = create_play_record(board_string);
    let declarer = determine_declarer(play_record, bidding_record, deal);
    let tricks = determine_tricks(
      board_string,
      declarer!,
      contract,
      play_record,
      deal
    );
    var board_record = new BoardRecord(
      bidding_record,
      raw_bidding,
      play_record,
      declarer!,
      contract,
      tricks,
      [],
      playerNames,
      0,
      deal
    );
    return board_record;
  };

  const determine_tricks = (
    board_string: string,
    declarer: Direction,
    contract: Contract,
    play_record: Card[],
    deal: Deal
  ) => {
    if (contract.level === 0) return 0;
    let claim = board_string.indexOf("|mc|");
    if (claim > 0) {
      let subString = board_string.substring(
        board_string.indexOf("|mc|") + 4,
        board_string.indexOf("|zz|")
      );
      return parseInt(subString);
    }
    let trump_suit = contract.suit;
    let lead_direction = Direction.next(declarer);
    let partner = Direction.partner(declarer);
    let offensive_tricks = 0;

    for (let i = 0; i < 52; i = i + 4) {
      let leading_suit = play_record[i].suit;
      let winningCard = evaulate_tick(
        play_record.slice(i, i + 4),
        trump_suit!,
        leading_suit!
      );
      if (
        determine_who_has_card(deal, winningCard!) === partner ||
        determine_who_has_card(deal, winningCard!) === declarer
      ) {
        offensive_tricks++;
      }
    }
    return offensive_tricks;
  };

  const evaulate_tick = (
    cards: Card[],
    trump_suit: BiddingSuit,
    leading_suit: Suit
  ) => {
    let data: any = [];
    let trump_cards: any = [];
    let trump_cards_to_sort: any = [];
    cards.forEach((card) => {
      if (card.suit === Suit.fromBiddingSuit(trump_suit)) {
        trump_cards.push(card);
        trump_cards_to_sort.push(card.rank);
      } else if (card.suit === leading_suit) {
        data.push(card.rank);
      }
    });
    if (trump_cards_to_sort.length > 0) {
      let result = trump_cards_to_sort.sort(Rank.predicate);
      let winning_trump = result[0];
      for (let i = 0; i < cards.length; i++) {
        if (
          cards[i].rank === winning_trump &&
          cards[i].suit === Suit.fromBiddingSuit(trump_suit)
        ) {
          return cards[i];
        }
      }
    } else {
      let result2 = data.sort(Rank.predicate);
      for (let i = 0; i < cards.length; i++) {
        if (cards[i].rank === result2[0] && cards[i].suit === leading_suit) {
          return cards[i];
        }
      }
      console.log("could not find card");
    }
  };

  const determine_who_has_card = (deal: Deal, card: Card) => {
    for (let i = 0; i < deal.hands.length; i++) {
      for (let j = 0; j < deal.hands[i].playerHand.cards.length; j++) {
        if (
          deal.hands[i].playerHand.cards[j].rank === card.rank &&
          deal.hands[i].playerHand.cards[j].suit === card.suit
        ) {
          return deal.hands[i].direction;
        }
      }
    }
  };

  const determine_declarer = (
    play_record: Card[],
    bidding_record: string[],
    deal: Deal
  ) => {
    if (bidding_record.length === 4) {
      return deal.dealer;
    }
    let first_card = play_record[0];
    for (let i = 0; i < deal.hands.length; i++) {
      for (let j = 0; j < deal.hands[i].playerHand.cards.length; j++) {
        if (
          deal.hands[i].playerHand.cards[j].rank === first_card.rank &&
          deal.hands[i].playerHand.cards[j].suit === first_card.suit
        ) {
          return Direction.previous(deal.hands[i].direction);
        }
      }
    }
  };

  const create_play_record = (board_string: string): Card[] => {
    let card_array: Card[] = [];
    let claim = board_string.indexOf("|mc|");
    if (claim > 0) {
      let string_array = board_string
        .substring(
          board_string.indexOf("|pc|") + 4,
          board_string.indexOf("|mc|")
        )
        .split("|pc|");
      string_array.forEach((element) => {
        card_array.push(CardFromString.fromString(element));
      });
      return card_array;
    }

    let string_array = board_string
      .substring(board_string.indexOf("|pc|") + 4, board_string.indexOf("|zz|"))
      .split("|pc|");
    string_array.forEach((element) => {
      card_array.push(CardFromString.fromString(element));
    });
    return card_array;
  };

  const create_contract_from_string = (contract: string) => {
    let working_contract = contract;
    if (working_contract === "PASS") return new Contract(0, null, 0);
    let doubled = (working_contract.match(/X/g) || []).length;
    if (doubled > 0) {
      working_contract = working_contract.replace(/X/g, "");
    }
    let level = working_contract[0];
    let bidding_suit = BiddingSuit.fromString(working_contract.substring(1));

    return new Contract(parseInt(level), bidding_suit, doubled);
  };

  const bidding_record_from_raw = (raw_bidding: string[]): string[] => {
    let bidding_record = [];
    for (let i = 0; i < raw_bidding.length; i++) {
      let element = raw_bidding[i].toUpperCase();
      if (element.endsWith("N")) element = element + "T";
      if (element === "DBL" || element === "D") element = "X";
      if (element === "REDBL" || element === "R") element = "XX";
      if (element === "P") element = "PASS";
      bidding_record.push(element);
    }
    return bidding_record;
  };

  const parse_deal = (board_string: string): Deal => {
    var dealer_string = board_string.substring(
      board_string.indexOf("|md|") + 4,
      board_string.indexOf("|md|") + 5
    );

    var holding_string = board_string
      .substring(board_string.indexOf("|md|") + 5)
      .split("|")[0];
    return create_deal(dealer_string, holding_string);
  };

  const create_deal = (dealer_string: string, holding_string: string) => {
    var hands: Hand[] = [];
    var player_hands = parse_hands(holding_string);
    for (let i = 1; i < player_hands.length + 1; i++) {
      hands.push(new Hand(i, player_hands[i - 1]));
    }
    var deal = new Deal(parseInt(dealer_string), hands);
    return deal;
  };

  const parse_hands = (holding_string: string) => {
    var player_hands: PlayerHand[] = [];
    var deckset2 = deckset.slice();
    var hands = holding_string.split(",");
    var reg = /H|D|C/;
    hands.forEach((hand) => {
      var card_array: Card[] = [];
      var split_hands = hand.substring(1).split(reg);
      var i: number = 1;
      split_hands.forEach((suit) => {
        suit.split("").forEach((card) => {
          let cardtoAdd: Card = new Card(i, card as Rank);
          card_array.push(cardtoAdd);
          var indexToRemove: number = -1;
          for (let index = 0; index < deckset2.length; index++) {
            if (
              deckset2[index].suit == cardtoAdd.suit &&
              deckset2[index].rank == cardtoAdd.rank
            ) {
              indexToRemove = deckset2.indexOf(deckset2[index]);
              break;
            }
          }
          if (indexToRemove > -1) {
            deckset2.splice(indexToRemove, 1);
          }
        });
        i++;
      });
      if (card_array.length > 0) player_hands.push(new PlayerHand(card_array));
    });
    var newArray = deckset2.slice();
    player_hands.push(new PlayerHand(newArray));
    return player_hands;
  };

  const combine_header = (contents: string) => {
    var combined = "";
    var lines = splitLines(contents);
    lines.every((line) => {
      combined += line.replace("\n", "");
      if (combined.endsWith("|pg||")) {
        return false;
      }
    });
    return combined;
  };

  const splitLines = (contents: string) => {
    const split = (str: string) => str.split(/\r?\n/);
    return split(contents);
  };

  const generatePDF = () => {
    let style: any = document.getElementById("gCSS");
    if (style) {
      console.log("got style");
      style.innerHTML =
        ".pdf_col {margin-top: -8px; height: 30px; font-size:14px; width:auto;} .pdf_width {width: 1680px}";
      console.log(style);
      // document.getElementsByTagName("head")[0].appendChild(style);
    }
    const report = new JsPDF("landscape", "pt", "a2");
    const doc = document.getElementById("dd");
    report.html(doc!).then(() => {
      report.save("BridgeScore.pdf");
      if (style) {
        style.innerHTML = "";
      }
    });
  };

  const dragStyle = () => {
    return (
      <div className=" flex items-center justify-center border-dashed border-2 border-indigo-600 h-32 w-96 rounded-lg align-middle cursor-pointer">
        <div className="flex items-center justify-center">
          <img className="h-20 mr-4" src={cards} alt="" />
          <p className="text-lg">Upload or drop .lin file here</p>
        </div>
      </div>
    );
  };

  const setHand = (event: any) => {
    setHandsCheck(event.target.checked);
  };
  const setSlam = (event: any) => {
    setSlamCheck(event.target.checked);
  };
  const setOpen = (event: any) => {
    setOpenCheck(event.target.checked);
  };
  const setHonors = (event: any) => {
    setHonorsCheck(event.target.checked);
  };

  return (
    <div>
      {rubbers.length <= 0 && (
        <div>
          <div className="grid flex h-screen items-center justify-center w-full">
            <div className="">
              <FileUploader
                handleChange={handleChange}
                name="file"
                types={fileTypes}
                label="Upload or drop .lin file here"
                children={dragStyle()}
              />
              <div className="mt-10">
                <span>Or Select a date range to read from database</span>
                <div className="mb-4">
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="username"
                    type="text"
                    placeholder="Username"
                    onChange={(e) => {
                      handleUserNameChange(e);
                    }}
                  />
                </div>
                <Datepicker
                  value={value}
                  onChange={handleValueChange}
                  disabled={userName == null}
                />{" "}
              </div>
            </div>
          </div>
        </div>
      )}

      {rubbers.length > 0 && (
        <div className="grid grid-cols-2 flex items-center justify-center">
          <div className="text-right">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-5"
              onClick={generatePDF}
            >
              Create PDF
            </button>
          </div>

          <div>
            <div className="flex items-center ">
              <input
                id="hands-checkbox"
                type="checkbox"
                onChange={setHand}
                checked={handsCheck}
                value=""
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                Hands
              </label>
            </div>
            <div className="flex items-center">
              <input
                id="slams-checkbox"
                type="checkbox"
                onChange={setSlam}
                value=""
                checked={slamCheck}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                Slams
              </label>
            </div>
            <div className="flex items-center">
              <input
                id="open-checkbox"
                type="checkbox"
                onChange={setOpen}
                checked={openCheck}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                Opening Hands
              </label>
            </div>
            <div className="flex items-center">
              <input
                id="open-checkbox"
                type="checkbox"
                onChange={setHonors}
                checked={honorsCheck}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                Honors
              </label>
            </div>
          </div>
        </div>
      )}
      <div id="dd" className="grid flex items-center justify-center pdf_width">
        <div className="flex items-center justify-center">
          <div className="grid grid-cols-1 gap-2 w-9/12">
            {rubberComponents?.map((rubber_component, key) => (
              <div key={key}>
                {(rubber_component.ns_points > 0 ||
                  rubber_component.ew_points > 0) &&
                  games.length < 2 && (
                    <RubberComponent
                      rubber={rubber_component}
                      name={"Rubber " + (key + 1).toString()}
                    />
                  )}
              </div>
            ))}
          </div>
        </div>
        {/* <div className="grid grid-cols-1 gap-2 w-auto">
          {BoardRecords.length > 0 && (
            <Summary
              boardRecords={BoardRecords}
              rubbers={rubbers}
              handsCheck={handsCheck}
              slamCheck={slamCheck}
              openCheck={openCheck}
              honorsCheck={honorsCheck}
            />
          )}
        </div> */}
        <div className="grid grid-cols-1 gap-2 w-auto">
          {games?.map((game, key) => (
            <div key={key}>
              <Summary
                game={game}
                handsCheck={handsCheck}
                slamCheck={slamCheck}
                openCheck={openCheck}
                honorsCheck={honorsCheck}
              />
            </div>
          ))}
        </div>
        {games.length > 1 && (
          <div className="grid grid-cols-1 gap-2 w-auto">
            <GameSummary
              games={games}
              handsCheck={handsCheck}
              slamCheck={slamCheck}
              openCheck={openCheck}
              honorsCheck={honorsCheck}
            />
          </div>
        )}
        ;
      </div>
    </div>
  );
};
