import BoardRecord from "../BoardRecord/BoardRecord";
import Rubber from "../Rubber/Rubber";

export default class Game {
  board_records;
  rubbers;
  date;

  constructor(board_records: BoardRecord[], rubbers: Rubber[], date: Date) {
    this.board_records = board_records;
    this.rubbers = rubbers;
    this.date = date;
  }
}
