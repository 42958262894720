import { percentage } from "../lib/scoring_logic";
import BoardRecord from "../model/BoardRecord/BoardRecord";
import { Direction } from "../model/Deal/Direction";
import Game from "../model/Game/Game";

type Props = {
  games: Game[];
  names: string[];
};

export const SlamsMade = ({ games, names }: Props) => {
  const calculate_made_slams = () => {
    let slamsMadeToReturn: number[] = [];
    slamsMadeToReturn[Direction.North] = 0;
    slamsMadeToReturn[Direction.South] = 0;
    slamsMadeToReturn[Direction.East] = 0;
    slamsMadeToReturn[Direction.West] = 0;
    let slamsAttemptedToReturn: number[] = [];
    slamsAttemptedToReturn[Direction.North] = 0;
    slamsAttemptedToReturn[Direction.South] = 0;
    slamsAttemptedToReturn[Direction.East] = 0;
    slamsAttemptedToReturn[Direction.West] = 0;
    games.forEach((game) => {
      game.board_records.forEach((board) => {
        if (board.declarer === Direction.North) {
          if (board.contract.level > 5) {
            slamsAttemptedToReturn[Direction.North]++;
            if (board.made === true) slamsMadeToReturn[Direction.North]++;
          }
        }
        if (board.declarer === Direction.South) {
          if (board.contract.level > 5) {
            slamsAttemptedToReturn[Direction.South]++;
            if (board.made === true) slamsMadeToReturn[Direction.South]++;
          }
        }
        if (board.declarer === Direction.East) {
          if (board.contract.level > 5) {
            slamsAttemptedToReturn[Direction.East]++;
            if (board.made === true) slamsMadeToReturn[Direction.East]++;
          }
        }
        if (board.declarer === Direction.West) {
          if (board.contract.level > 5) {
            slamsAttemptedToReturn[Direction.West]++;
            if (board.made === true) slamsMadeToReturn[Direction.West]++;
          }
        }
      });
    });

    return [slamsAttemptedToReturn, slamsMadeToReturn];
  };

  const [slamsattempted, slamsMade] = calculate_made_slams();
  return (
    <div className="grid grid-cols-2" style={{ textAlign: "center" }}>
      <div
        className="col-span-2 bg-slate-50 font-bold border-l-2 border-r-2"
        style={{ textAlign: "center" }}
      >
        Slams
      </div>
      <div className="bg-slate-200">{names[Direction.North]}</div>
      <div className="bg-slate-200">
        {slamsattempted[Direction.North] +
          "/" +
          slamsMade[Direction.North] +
          " (" +
          percentage(
            slamsMade[Direction.North],
            slamsattempted[Direction.North]
          ) +
          " %)"}
      </div>
      <div className="bg-slate-50">{names[Direction.South]}</div>
      <div className="bg-slate-50 ">
        {slamsattempted[Direction.South] +
          "/" +
          slamsMade[Direction.South] +
          " (" +
          percentage(
            slamsMade[Direction.South],
            slamsattempted[Direction.South]
          ) +
          " %)"}
      </div>
      <div className="bg-slate-200">{names[Direction.East]}</div>
      <div className="bg-slate-200">
        {slamsattempted[Direction.East] +
          "/" +
          slamsMade[Direction.East] +
          " (" +
          percentage(
            slamsMade[Direction.East],
            slamsattempted[Direction.East]
          ) +
          " %)"}
      </div>
      <div className="bg-slate-50">{names[Direction.West]}</div>
      <div className="bg-slate-50">
        {slamsattempted[Direction.West] +
          "/" +
          slamsMade[Direction.West] +
          " (" +
          percentage(
            slamsMade[Direction.West],
            slamsattempted[Direction.West]
          ) +
          " %)"}
      </div>
    </div>
  );
};
