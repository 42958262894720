import { BiddingSuit } from "./BiddingSuit";

export default class Contract {
  level;
  suit;
  doubled;

  constructor(level: number, suit: BiddingSuit | null, doubled: number) {
    this.level = level;
    this.suit = suit;
    this.doubled = doubled;
  }
}
