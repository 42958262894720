import { Direction } from "../model/Deal/Direction";

type Props = {
  names: string[];
  openingHands: number[];
};

export const OpeningHands = ({ names, openingHands }: Props) => {
  return (
    <div
      className="grid grid-cols-2 border-l-2 border-r-2"
      style={{ textAlign: "center" }}
    >
      <div
        className="col-span-2 bg-slate-50 font-bold "
        style={{ textAlign: "center" }}
      >
        Opening Hands
      </div>
      <div className="bg-slate-200">{names[Direction.North]}</div>
      <div className="bg-slate-200">{openingHands[Direction.North]}</div>
      <div className="bg-slate-50">{names[Direction.South]}</div>
      <div className="bg-slate-50 ">{openingHands[Direction.South]}</div>
      <div className="bg-slate-200">{names[Direction.East]}</div>
      <div className="bg-slate-200">{openingHands[Direction.East]}</div>
      <div className="bg-slate-50">{names[Direction.West]}</div>
      <div className="bg-slate-50">{openingHands[Direction.West]}</div>
    </div>
  );
};
