import { Direction } from "./Direction";
import PlayerHand from "./PlayerHand";

export default class Hand {
  direction;
  playerHand;

  constructor(direction: Direction, playerHand: PlayerHand) {
    this.direction = direction;
    this.playerHand = playerHand;
  }
}
