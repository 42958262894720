import { useState } from "react";
import { BoardRecords } from "../components/DragDrop";
import { BiddingSuit } from "../model/BoardRecord/BiddingSuit";
import BoardRecord from "../model/BoardRecord/BoardRecord";
import { Direction } from "../model/Deal/Direction";
import Rubber from "../model/Rubber/Rubber";
import { Dir } from "fs";

var NorthName = "";
var SouthName = "";
var EastName = "";
var WestName = "";
export const rubbers: Rubber[] = [];

export const getRubberScoring = () => {
  console.log(BoardRecords);
  getNames();
  calculate_rubbers();
  for (let i = 0; i < rubbers.length; i++) {
    console.log("Rubber " + i, rubbers[i]);
  }
};

export const getNames = () => {
  let names = [];

  names[Direction.North] = BoardRecords[0].names.filter(
    (x) => x.direction === Direction.North
  )[0].name;

  names[Direction.South] = BoardRecords[0].names.filter(
    (x) => x.direction === Direction.South
  )[0].name;

  names[Direction.East] = BoardRecords[0].names.filter(
    (x) => x.direction === Direction.East
  )[0].name;

  names[Direction.West] = BoardRecords[0].names.filter(
    (x) => x.direction === Direction.West
  )[0].name;
  return names;
};

export function percentage(partialValue: number, totalValue: number) {
  if (totalValue === 0) return 0;
  return ((100 * partialValue) / totalValue).toFixed(2);
}

const calculate_rubbers = () => {
  let rubber = new Rubber();
  rubbers.push(rubber);
    BoardRecords.forEach((board_record) => {
    // add up player points
    rubber.north_points += board_record.deal.hands
      .filter((x) => x.direction === Direction.North)[0]
      .playerHand.getPoints();
    rubber.south_points += board_record.deal.hands
      .filter((x) => x.direction === Direction.South)[0]
      .playerHand.getPoints();

    rubber.east_points += board_record.deal.hands
      .filter((x) => x.direction === Direction.East)[0]
      .playerHand.getPoints();

    rubber.west_points += board_record.deal.hands
      .filter((x) => x.direction === Direction.West)[0]
      .playerHand.getPoints();
    rubber.games_played++;
    rubber = calculate_rubber_points(board_record, rubber);
    // console.log(rubber);
  });
};

const calculate_rubber_points = (
  board_record: BoardRecord,
  rubber: Rubber
): Rubber => {
  let vulnerable = false;
  let points = 0;
  let returnRubber = rubber;
  if (
    board_record.declarer === Direction.North ||
    board_record.declarer === Direction.South
  ) {
    vulnerable = returnRubber.ns_vulnerable;
  } else {
    vulnerable = returnRubber.ew_vulernable;
  }

  if (board_record.contract.level === 0) return returnRubber;

  let scoring_tricks = board_record.tricks - 6;
  let level = board_record.contract.level;
  console.log("contract", board_record.contract);

  // If contract is made
  if (scoring_tricks >= level) {
    let double_multiplier = Math.pow(2, board_record.contract.doubled);
    let first_trick_score =
      BiddingSuit.firstTrickValue(board_record.contract.suit!) *
      double_multiplier;
    let subsequent_trick_score =
      BiddingSuit.trickValue(board_record.contract.suit!) *
      double_multiplier *
      (level - 1);
    let bonus = calculate_bonus(
      level,
      board_record.contract.suit!,
      board_record.contract.doubled,
      vulnerable,
      first_trick_score + subsequent_trick_score,
      scoring_tricks - level
    );
    console.log(
      "first_trick_score",
      first_trick_score,
      "subsequent",
      subsequent_trick_score,
      "bonus",
      bonus
    );
    points = first_trick_score + subsequent_trick_score + bonus;
    let rubber_points = first_trick_score + subsequent_trick_score;

    console.log("points", points);
    if (
      board_record.declarer === Direction.North ||
      board_record.declarer === Direction.South
    ) {
      
      returnRubber.ns_points += Math.abs(points);
    } else {
      
      returnRubber.ew_points += Math.abs(points);
    }

    //calculate leg
    if (
      board_record.declarer === Direction.North ||
      board_record.declarer === Direction.South
    ) {
      if (rubber_points + returnRubber.ns_leg >= 100) {
        if (returnRubber.ns_vulnerable) {
          if (returnRubber.ew_vulernable) {
            returnRubber.ns_points += 500;
            returnRubber = create_new_rubber(returnRubber);
          } else {
            returnRubber.ns_points += 700;
            returnRubber = create_new_rubber(returnRubber);
          }
        } else {
          returnRubber.ns_vulnerable = true;
          returnRubber.ns_leg = 0;
          returnRubber.ew_leg = 0;
        }
      } else {
        returnRubber.ns_leg += rubber_points;
      }
    } else {
      if (rubber_points + returnRubber.ew_leg >= 100) {
        if (returnRubber.ew_vulernable) {
          if (returnRubber.ns_vulnerable) {
            rubber.ew_points += 500;
            returnRubber = create_new_rubber(returnRubber);
          } else {
            returnRubber.ew_points += 700;
            returnRubber = create_new_rubber(returnRubber);
          }
        } else {
          returnRubber.ew_vulernable = true;
          returnRubber.ew_leg = 0;
          returnRubber.ns_leg = 0;
        }
      } else {
        returnRubber.ew_leg += rubber_points;
      }
    }
  } else {
    let undertricks = level + 6 - board_record.tricks;
    console.log("undertricks", undertricks);
    for (let i = 0; i < undertricks; i++) {
      if (i === 0)
        points -= BiddingSuit.firstUndertrickValue(
          vulnerable,
          board_record.contract.doubled
        );
      else if (i === 1)
        points -= BiddingSuit.secondUndertrickValue(
          vulnerable,
          board_record.contract.doubled
        );
      else
        points -= BiddingSuit.subsequentUndertrickValue(
          vulnerable,
          board_record.contract.doubled
        );
    }
    console.log("points", points);
    if (
      board_record.declarer === Direction.North ||
      board_record.declarer === Direction.South
    ) {
      returnRubber.ew_points += Math.abs(points);
    } else {
      returnRubber.ns_points += Math.abs(points);
    }
    // calculate honors
    console.log("points", points);

      //add honors
      console.log("board recoreds", board_record);
      board_record.deal.hands.forEach((hand) => {
        console.log("hand");
      console.log(hand);
        if (
          hand.direction === Direction.North ||
          hand.direction === Direction.South
        ) {
          console.log("adding honors");
          returnRubber.ns_points += hand.playerHand.getHonors(board_record.contract.suit!);
          console.log(points);
        }
      });

      board_record.deal.hands.forEach((hand) => {
        if (
          hand.direction === Direction.East ||
          hand.direction === Direction.West
        ) {
          console.log("adding honors");
          returnRubber.ew_points += hand.playerHand.getHonors(board_record.contract.suit!);
          console.log(points);
        }
      });
    
  }

  return returnRubber;
};

const create_new_rubber = (rubber: Rubber): Rubber => {
  console.log("creating new rubber");
  console.log(rubber);
  let newRubber = new Rubber();
  rubbers.push(newRubber);
  return newRubber;
};

const calculate_bonus = (
  level: number,
  suit: BiddingSuit,
  doubled: number,
  vulnerable: boolean,
  contracted_trick_score: number,
  overtricks: number
): number => {
  let score = 0;

  //slam bonus
  if (level === 7) score += vulnerable ? 1500 : 1000;
  else if (level === 6) score += vulnerable ? 750 : 500;

  //overtricks
  if (doubled === 0) {
    score += overtricks * BiddingSuit.trickValue(suit);
  } else if (doubled === 1) {
    score += 50;
    score += overtricks * (vulnerable === true ? 200 : 100);
  } else if (doubled === 2) {
    score += 100;
    score += overtricks * (vulnerable === true ? 400 : 200);
  }

  return score;
};
