import { BiddingSuit } from "../BoardRecord/BiddingSuit";
import Card from "./Card";
import { Rank } from "./Rank";
import { Suit } from "./Suit";

export default class PlayerHand {
  cards;

  constructor(cards: Card[]) {
    this.cards = cards;
  }

  getPoints(): number {
    let points = 0;
    this.cards.forEach((card) => {
      if (card.rank === Rank.ACE) points += 4;
      if (card.rank === Rank.KING) points += 3;
      if (card.rank === Rank.QUEEN) points += 2;
      if (card.rank === Rank.JACK) points += 1;
    });
    return points;
  }

  getHonors(suit: BiddingSuit): number {
    let honors = 0;
    if (suit === BiddingSuit.NO_TRUMP) {
      this.cards.forEach((card) => {
        if (card.rank === Rank.ACE) honors += 1;
      });
    } else {
      this.cards.forEach((card) => {
        if (card.suit === Suit.fromBiddingSuit(suit)) {
          if (card.rank === Rank.ACE) {
            honors += 1;
          } else if (card.rank === Rank.KING) {
            honors += 1;
          } else if (card.rank === Rank.QUEEN) {
            honors += 1;
          } else if (card.rank === Rank.JACK) {
            honors += 1;
          } else if (card.rank === Rank.TEN) {
            honors += 1;
          }
        }
      });
    }

    if (honors === 4) {
      console.log("little honors");
      return 100;
    } else if (honors === 5) {
      console.log("big honors");
      return 150;
    } else {
      return 0;
    }
  }
}
