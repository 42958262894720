import { Rank } from "./Rank";
import { Suit } from "./Suit";

export default class Card {
  suit;
  rank;

  constructor(suit: Suit | undefined, rank: Rank) {
    this.suit = suit;
    this.rank = rank;
  }
}

export namespace CardFromString {
  export function fromString(string: string): Card {
    let suit = string[0];
    let rank = string[1];

    return new Card(Suit.fromString(suit), rank as Rank);
  }
}
