import { percentage } from "../lib/scoring_logic";
import BoardRecord from "../model/BoardRecord/BoardRecord";
import { Direction } from "../model/Deal/Direction";
import Game from "../model/Game/Game";

type Props = {
  games: Game[];
  names: string[];
};

export const HandsMade = ({ games, names }: Props) => {
  const calculate_made_hands = () => {
    let handsMadeToReturn: number[] = [];
    handsMadeToReturn[Direction.North] = 0;
    handsMadeToReturn[Direction.South] = 0;
    handsMadeToReturn[Direction.East] = 0;
    handsMadeToReturn[Direction.West] = 0;
    let handsAttemptedToReturn: number[] = [];
    handsAttemptedToReturn[Direction.North] = 0;
    handsAttemptedToReturn[Direction.South] = 0;
    handsAttemptedToReturn[Direction.East] = 0;
    handsAttemptedToReturn[Direction.West] = 0;
    games.forEach((game) => {
      game.board_records.forEach((board) => {
        if (board.declarer === Direction.North) {
          handsAttemptedToReturn[Direction.North]++;
          if (board.made === true) handsMadeToReturn[Direction.North]++;
        }
        if (board.declarer === Direction.South) {
          handsAttemptedToReturn[Direction.South]++;
          if (board.made === true) handsMadeToReturn[Direction.South]++;
        }
        if (board.declarer === Direction.East) {
          handsAttemptedToReturn[Direction.East]++;
          if (board.made === true) handsMadeToReturn[Direction.East]++;
        }
        if (board.declarer === Direction.West) {
          handsAttemptedToReturn[Direction.West]++;
          if (board.made === true) handsMadeToReturn[Direction.West]++;
        }
      });
    });

    return [handsAttemptedToReturn, handsMadeToReturn];
  };

  const [handsattempted, handsMade] = calculate_made_hands();
  return (
    <div
      className="grid grid-cols-2 border-l-2 border-r-2"
      style={{ textAlign: "center" }}
    >
      <div
        className="col-span-2 bg-slate-50 font-bold "
        style={{ textAlign: "center" }}
      >
        Hands
      </div>
      <div className="bg-slate-200">{names[Direction.North]}</div>
      <div className="bg-slate-200">
        {handsattempted[Direction.North] +
          "/" +
          handsMade[Direction.North] +
          " (" +
          percentage(
            handsMade[Direction.North],
            handsattempted[Direction.North]
          ) +
          " %)"}
      </div>
      <div className="bg-slate-50">{names[Direction.South]}</div>
      <div className="bg-slate-50 ">
        {handsattempted[Direction.South] +
          "/" +
          handsMade[Direction.South] +
          " (" +
          percentage(
            handsMade[Direction.South],
            handsattempted[Direction.South]
          ) +
          " %)"}
      </div>
      <div className="bg-slate-200">{names[Direction.East]}</div>
      <div className="bg-slate-200">
        {handsattempted[Direction.East] +
          "/" +
          handsMade[Direction.East] +
          " (" +
          percentage(
            handsMade[Direction.East],
            handsattempted[Direction.East]
          ) +
          " %)"}
      </div>
      <div className="bg-slate-50">{names[Direction.West]}</div>
      <div className="bg-slate-50">
        {handsattempted[Direction.West] +
          "/" +
          handsMade[Direction.West] +
          " (" +
          percentage(
            handsMade[Direction.West],
            handsattempted[Direction.West]
          ) +
          " %)"}
      </div>
    </div>
  );
};
