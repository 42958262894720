import Card from "../Deal/Card";
import Deal from "../Deal/Deal";
import { Direction } from "../Deal/Direction";
import Contract from "./Contract";
import Name from "./Name";

export default class BoardRecord {
  bidding_record;
  raw_bidding_record;
  play_record;
  declarer;
  contract;
  tricks;
  scoring;
  names;
  score;
  zach_score;
  made = false;
  deal;

  constructor(
    bidding_record: string[],
    raw_bidding_record: string[],
    play_record: Card[],
    declarer: Direction,
    contract: Contract,
    tricks: number,
    scoring: string[],
    names: Name[],
    score: number,
    deal: Deal
  ) {
    this.bidding_record = bidding_record;
    this.raw_bidding_record = raw_bidding_record;
    this.play_record = play_record;
    this.declarer = declarer;
    this.contract = contract;
    this.tricks = tricks;
    this.scoring = scoring;
    this.names = names;
    this.score = score;
    this.zach_score = 0;
    this.deal = deal;
    if (this.tricks - 6 >= this.contract.level) this.made = true;
  }
}
